import React, { useEffect } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Typography,
  Box,
  Link,
  Paper,
  Container,
  Icon,
  Grid,
  styled,
  IconButton,
  Card,
  CardContent,
  Drawer,
  Toolbar,
  SvgIcon,
  Stack,
  keyframes,
} from "@mui/material";
//import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import Carousel from "react-material-ui-carousel";
import TuneIcon from "@mui/icons-material/Tune";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import FilterListIcon from "@mui/icons-material/FilterList";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import logo from "../../assets/logoBranca.svg";
import imgs from "../../assets/ss.webp";
import imgs2 from "../../assets/img2.webp";
import backgroundHome from "../../assets/overlay_4.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logobi from "../../assets/logosServicos/LOGOEASYBI.svg";
import logomaps from "../../assets/logosServicos/LOGOEASYMAPS.svg";
import logoreport from "../../assets/logosServicos/LOGOEASYREPORT.svg";

import mon from "../../assets/NOVO-01.png";
import flow from "../../assets/NOVO-02.png";
import phoneWhats from "../../assets/phoneWhats.svg";
import phoneMap from "../../assets/phonemap.svg";
import phoneHub from "../../assets/phonehub.svg";
import phoneMw from "../../assets/phonemw.svg";
import mompc from "../../assets/monpc.webp";
import flowpc from "../../assets/flowpc.webp";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckIcon from "@mui/icons-material/Check";
import MapIcon from "@mui/icons-material/Map";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import DiamondIcon from "@mui/icons-material/Diamond";
import MovingIcon from "@mui/icons-material/Moving";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import SpeedIcon from "@mui/icons-material/Speed";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import logoex1 from "../../assets/logosClientes/ALLREDE_p.png";
import logoex2 from "../../assets/logosClientes/IBL_p.png";
import logoex3 from "../../assets/logosClientes/WSP_p.png";
import logoex4 from "../../assets/logosClientes/new_master_telecom_p.png";
import logoex5 from "../../assets/logosClientes/onetech_p.png";
import MapChart from "../../components/mapcharts";
import TextScramble from "../../components/scramble"; // Supondo que o componente TextScramble esteja em um arquivo separado chamado TextScramble.js

import ex from "../../assets/exempl1.png";

import * as styles from "./style";

import generatedGitInfo from "../../generatedGitInfo.json";

import AOS from "aos";
import "aos/dist/aos.css";

const injectStyle = (style) => {
  const styleElement = document.createElement("style");
  let styleSheet = null;
  document.head.appendChild(styleElement);
  styleSheet = styleElement.sheet;
  styleSheet.insertRule(style, styleSheet.cssRules.length);
};

function Home() {
  //const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pageTopo, setpageTopo] = React.useState(true);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("mdx"));
  const isMobileMin = useMediaQuery((theme) => theme.breakpoints.down("xsp"));
  const [imgPhone, setImgPhone] = React.useState(phoneMw);

  const keyframesStyle = `
      @-webkit-keyframes pulse {
        from { transform: translate(0,  0px); }
        65%  { transform: translate(0, 15px); }
        to   { transform: translate(0, -0px); }   
      }
     
    `;
  const cursor = `
    @keyframes blinking {
      0% { opacity: 0; }
      50% { opacity: 1; }
      100% { opacity: 0; }
    }
   
  `;
  injectStyle(cursor);

  injectStyle(keyframesStyle);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    function handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 5) setpageTopo(false);
      else setpageTopo(true);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDesktop]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const showAgenda = () => {
    setImgPhone(phoneHub);
  };
  const showwhats = () => {
    setImgPhone(phoneWhats);
  };
  const showMaps = () => {
    setImgPhone(phoneMap);
  };
  const leavephone = () => {
    setImgPhone(phoneMw);
  };
  const sendWhats = (text) => {
    window.location.href = "https://wa.me/558491540831?text=" + text;
  };

  const moveImage = keyframes`
  from{
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

  // Defina a animação de espelhamento
  const mirrorImage = keyframes`
  from{
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
`;

  const moveImage2 = keyframes`
  from{
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
  `;

  // Defina a animação de espelhamento
  const mirrorImage2 = keyframes`
  from{
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
  `;

  const services = [
    {
      title: "Disaster Recovery",
      icon: CloudSyncIcon,
      text: "Nosso produtos possuem a capacidade de restaurar o sistema em caso de desastres, como eventos de falta de energia ou falhas de hardware! Isso garante a continuidade dos negócios, pois permite a recuperação dos dados sem perdas, preservando assim a integridade e funcionalidade do sistema.",
    },
    {
      title: "Alta Disponibilidade",
      icon: AvTimerIcon,
      text: "Nossas ferramentas estão prontas para manter o sistema ou aplicação em funcionamento continuamente, minimizando a chance de falhas e interrupções. Isso é alcançado por meio de redundância e backup de componentes críticos para garantir a continuidade do serviço mesmo em caso de falha.",
    },
    {
      title: "Atendimento Humanizado",
      icon: HandshakeIcon,
      text: "Na MW Soluções, dispensamos a formalidade dos robôs. Ao entrar em contato conosco, não precisa passar por listas de perguntas automáticas. Em vez disso, você será acolhido por uma equipe dedicada e genuinamente interessada em resolver seus desafios de maneira ágil e eficaz. Valorizamos cada cliente como único e estamos aqui para resolver seus problemas com um sorriso no rosto.",
    },
    {
      title: "Serviço Customizado",
      icon: AutoAwesomeMosaicIcon,
      text: "Oferecemos serviços de dashboards e coletas customizadas, fornecendo soluções de análise de dados adaptadas às necessidades específicas de cada cliente. Isso inclui flexibilidade na personalização de layouts e métricas, integração de dados de várias fontes, análise avançada para insights preditivos, suporte especializado e garantia de segurança e conformidade dos dados",
    },
  ];

  const clients = [
    { logo: logoex1 },
    { logo: logoex2 },
    { logo: logoex3 },
    { logo: logoex4 },
    { logo: logoex5 },
  ];

  const feedback = [
    {
      name: "Jorge",
      descricao: "Provedor - NETGO",
      comment:
        "A MW Soluções tem uma equipe de super profissionais, com certeza recomendo! Cumprem o prazo, e em eficiência de suporte ao cliente são nota 1.000!",
    },
    {
      name: "Otavio Castro",
      descricao: "GB online",
      comment:
        "A experiência com o Time MW nos surpreende pela eficiência no atendimento às solicitações de nossas demandas e pelo acompanhamento constante de inovações, com uma parceria que dura anos.",
    },
    {
      name: "Max Albuquerque",
      descricao: "Gerente de NOC - IBL",
      comment:
        "O comprometimento e celeridade com que tratam a parceira conosco, clientes, é verdadeiramente perceptível em cada detalhe. Seja num simples ajuste de parâmetro ou no desenvolvimento de uma nova case, o trabalho de vocês, a dedicação e os feedbacks nos fazem superar as expectativas diariamente. ",
    },
    {
      name: "Andrey",
      descricao: "OneTech",
      comment:
        "A empatia que o pessoal da MW tem com o cliente é algo absurdo! Agilidade nos processos, a forma de pensar, a execução de tudo aquilo que é pedido. Enquanto não estiver 100% eles não fecham demandas! Sou fã deles! Não tenho nada a reclamar nesses 4 anos, tudo o que eu pedi foi atendido da melhor maneira possível. Se você tem algo que precisa monitorar, algum gráfico, eles vão arranjar uma forma de te ajudar! Atendimento impecável, ferramenta impecável, a MW é impecável em si.",
    },
    {
      name: "Marcello",
      descricao: "Megalink",
      comment:
        "A equipe da MW Soluções possui pessoas que trabalham em prol de ajudar o provedor. As dash de monitoramento, a agilidade no atendimento fazem um diferencial entre as outras empresas, os seus funcionários são pessoas de fato capacitadas para as demandas solicitadas. Espero que permaneçam dessa forma, pois o diferencial hoje é o conhecimento e atendimento.",
    },
    {
      name: "Denis",
      descricao: "WaveMax.",
      comment:
        "Somos gratos, pela excelente prestação de serviço de monitoração, gerenciamento e follow-up prestados pela MW Soluções. O comprometido e celeridade com que tratam a parceira conosco.",
    },
  ];

  const tiers = [
    {
      title: "EasyMaps",
      color: "#e94159",
      image: logomaps,
      subtitle:
        "A ferramenta ideal para o monitoramento da infraestrutura de sua rede por meio de um mapa de backbone",
      description: [
        "Tráfego de entrada e saída em tempo real",
        "Fácil visualização e intepretação ",
        "Organização goegráfica da rede",
        "Identificação rápida de problemas nos tráfegos",
      ],
      buttonText: "Adquira ja!",
      buttonVariant: "contained",
    },
    {
      title: "EasyBI",
      color: "#039fdd",
      image: logobi,
      subtitle:
        "Ferramenta de monitoramento de Business Inteligence com base em Sistemas ERP",
      description: [
        "Sistemas ERP suportados: SGP e IXC",
        "Obtém as principais informações acerca da gestão operacional de OS e Ocorrências",
        "Permite a criação de alertas com base em informações fornecidas pelo ERP",
        "Cobre pontos cegos do ERP a partir de percepções do cliente e consultas personalizadas",
      ],
      buttonText: "Adquira ja!",
      buttonVariant: "contained",
    },
    {
      title: "EasyReport",
      color: "#f48206",
      image: logoreport,
      subtitle:
        "Ferramenta de Geração Automática de Relatórios de Disponibillidade e de Tráfego",
      description: [
        "Obtenção de relatórios de 95th das interfaces para tarifação",
        "Análise do tempo de disponibilidade de sessões BGP, interfaces e ativos da rede",
        "Relatório tarifação de Link, Interface e Disponibilidade",
      ],
      buttonText: "Aproveite!",
      buttonVariant: "contained",
    },
  ];

  const navItems = ["Home", "Produtos", "Serviços", "Sobre"];
  const PricingList = styled("ul")({
    margin: 0,
    padding: 0,
    listStyle: "none",
  });
  function scrollToSection(id) {
    const section = document.getElementById(id);
    const yOffset = -70; // valor de offset dapágina
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }

  function Copyright() {
    return (
      <Stack sx={{ color: "white", fontSize: "0.8rem" }}>
        <Typography>
          {"© 2018 - " + new Date().getFullYear() + " "}
          <Link color="primary.main" href="/">
            MW Soluções
          </Link>
          {". Todos os direitos reservados"}
        </Typography>
        <Typography sx={{ fontSize: "0.6rem" }}>
          Version: 1.0.1({generatedGitInfo.gitCommitHash})
        </Typography>
      </Stack>
    );
  }
  function CardProductsOthers({ tier, index }) {
    let interval = index * 50 + "";
    return (
      <Card
        sx={[styles.cardPrincing]}
        data-aos="slide-up"
        data-aos-delay={interval}
      >
        <Box
          sx={{
            width: "100%",
            background: "#333",
            //boxShadow: "0px 5px 5px -2px "+tier.color,
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={tier.image}
            style={{
              height: "35px",
              width: "auto",
            }}
          />
        </Box>
        <Typography
          sx={[{ fontSize: "1.5rem", mt: "10px" }, styles.textRubik300]}
        >
          {tier.title}
        </Typography>
        <Typography
          sx={[
            {
              fontSize: "1rem",
              width: "80%",
              minHeight: "50px",
              textAlign: "center",
            },
            styles.textRubik300,
          ]}
        >
          {tier.subtitle}
        </Typography>
        <CardContent sx={{ width: "85%" }}>
          <PricingList mb={2}>
            {tier.description.map((line) => (
              <Typography component="li" align="left" key={line}>
                <CheckIcon sx={{ fontSize: "15px" }} />
                <Typography
                  sx={[
                    {
                      marginLeft: "20px",
                      marginTop: "-28px",
                      fontSize: "1rem",
                    },
                    styles.textRubik300,
                  ]}
                >
                  {line}
                </Typography>
              </Typography>
            ))}
          </PricingList>
        </CardContent>
        <Stack
          width="100%"
          direction="row"
          justifyContent="right"
          sx={{ bottom: "0px", position: { mdx: "absolute" } }}
        >
          <Button
            color="primary"
            sx={{
              fontSize: "1rem",
              textTransform: "none",
              color: "black",
              fontWeight: 300,
            }}
            onClick={() =>
              sendWhats(
                "Olá%2C+estou+interessado+no+" +
                  tier.title +
                  "+e+gostaria+de+marcar+uma+reunião+para+discutir+mais+detalhes+do+produto.++Fico+no+aguardo%21"
              )
            }
          >
            Fale conosco
            <ArrowForwardIcon />
          </Button>
        </Stack>
      </Card>
    );
  }
  function StickIsinght({ value, text }) {
    return (
      <Stack
        spacing={0.6}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: { xs: "1.6rem", sm: "1.9rem" },
            color: "secondary.main",
            textShadow: "0px 0px 3px red",
          }}
        >
          {value}
        </Typography>

        <Typography
          sx={{
            maxWidth: { xs: "70px", xsm: "100px" },
            width: "min-content",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: { xs: "0.67rem", sm: "0.8rem" },
            lineHeight: "15px",
            color: "white",
          }}
        >
          {text}
        </Typography>
      </Stack>
    );
  }
  function Item({ name, descricao, comment, img }) {
    return (
      <Stack width="100%" alignItems="center">
        <Paper sx={[styles.cardFeedback]}>
          <Avatar
            alt={name}
            //src={user}
            sx={{
              display: "flex",
              width: "90px",
              height: "90px",
            }}
          />

          <Typography
            variant="h2"
            mt={1}
            sx={{
              fontSize: { xs: "1.2rem", fhd: "1.3rem", qhd: "1.5rem" },

              color: "chip.waitingReturn",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              marginTop: "0px",
              fontSize: { xs: "0.9rem", fhd: "1rem", qhd: "1.3rem" },
            }}
          >
            {descricao}
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              textAlign: "justify",
              fontSize: { xs: "0.9rem", fhd: "1rem", qhd: "1.3rem" },
              fontStyle: "italic",
            }}
          >
            "{comment}"
          </Typography>
        </Paper>
      </Stack>
    );
  }
  function CardDescription({ title, Icon, cor }) {
    return (
      <Card
        sx={{
          backgroundColor: "#333",
          width: { sm: "200px", xs: "100%" },
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "5px",
        }}
      >
        <Icon sx={{ color: cor, fontSize: "40px" }} />
        <Typography
          sx={{
            color: "white",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "0.86rem",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Card>
    );
  }

  return (
    <Box minWidth="360px" sx={{}}>
      {isDesktop && (
        <Box
          zIndex={-100}
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",

            // backgroundImage: `url(${appCurvyLines})`,
            //  backgroundColor: "#ffffffff",

            // background:`#eee 100%, center / contain no-repeat url(${backgroundHome})`,
            background: `linear-gradient(rgba(223, 223, 223, 0.75), rgba(223, 223, 223, 0.75)) ,center center / cover no-repeat url(${backgroundHome})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            alignItems: "center",
          }}
        ></Box>
      )}

      <Box zIndex={1} width="100%">
        <AppBar
          sx={[
            pageTopo ? styles.navBarTrans : styles.navBar,
            { display: "flex", flexDirection: "row", justifyContent: "center" },
          ]}
        >
          <Toolbar
            sx={[
              styles.contentSizeSection,
              { width: { xsp: "80vw", xs: "100vw" } },
            ]}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              mt={1}
              display={{ xs: "flex", mdx: "none" }}
            >
              <Box
                component="img"
                src={logo}
                sx={{ height: "auto", width: "200px" }}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { xs: "block", mdx: "none" } }}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                  width: "50%",
                  flexShrink: 0,
                  background: "rgba(0,0,0,0.9)",
                  color: "white!important",
                  "& .MuiDrawer-paper": {
                    width: "50%",
                    justifyContent: "center",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    backgroundImage: "none",
                  },
                  "& .MuiBackdrop-root-MuiModal-backdrop": {
                    background: "transparent",
                  },
                }}
              >
                <Box
                  onClick={handleDrawerToggle}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {navItems.map((item, index) => (
                    <Button
                      onClick={() => scrollToSection("section" + item)}
                      key={item + index + "b"}
                      sx={{
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {item}

                      <Box
                        sx={[
                          styles.line,
                          { background: "#1a1818", width: "100%" },
                        ]}
                      />
                    </Button>
                  ))}
                  <Button
                    color="secondary"
                    onClick={() => scrollToSection("sectionContato")}
                    variant="contained"
                    sx={{ my: 1, mx: 1.5, padding: "8px 25px" }}
                  >
                    Contato
                  </Button>
                </Box>
              </Drawer>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              mt={1}
              display={{ xs: "none", mdx: "flex" }}
            >
              <Icon
                component="div"
                style={{
                  height: "auto",
                  width: "auto",
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  sx={[
                    {
                      height: "auto",
                    },
                    isMobileMin ? { width: "50vw" } : { width: "220px" },
                  ]}
                />
              </Icon>

              <Box sx={styles.navbarContentButtons}>
                {navItems.map((item, index) => (
                  <Button
                    onClick={() => scrollToSection("section" + item)}
                    key={item + index + "a"}
                    sx={styles.navbarButtons}
                  >
                    {item}
                  </Button>
                ))}
                <Button
                  color="secondary"
                  onClick={() => scrollToSection("sectionContato")}
                  variant="contained"
                  sx={{ padding: "8px 25px" }}
                >
                  Contato
                </Button>
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>

        <Box
          component="section"
          sx={[
            styles.contentPages,

            {
              paddingRight: { mdx: "0px", qhd: "15vw" },

              width: "100%",
              height: "96vh",
              minHeight: "700px",
              maxHeight: "1500px",
              display: { sm: "block" },
              //backgroundImage: `url(${backgroundHome})`,
              background: `linear-gradient(rgba(0, 0, 0, 0.90), rgba(5, 0, 0, 0.90)), center center / cover no-repeat  url(${backgroundHome})`,
              backgroundAttachment: "fixed",
              //background: 'linear-gradient(rgba(22, 28, 36, 0.94), rgba(22, 28, 36, 0.94)) center center / cover no-repeat',
            },
          ]}
          id="sectionHome"
        >
          <Stack
            spacing={{ mdx: 2, xs: 0 }}
            sx={[styles.contentSizeSection, { margin: "auto" }]}
            direction={{ mdx: "row", xs: "column" }}
            justifyContent={{ xs: "center", mdx: "space-between" }}
            height="100%"
            width="100%"
            alignItems="center"
            overflow={"hidden"}
          >
            <Stack
              width={{ mdx: "50%", xs: "100%" }}
              height="100%"
              direction="column"
              justifyContent="center"
            >
              <Stack
                direction="column"
                spacing={3.5}
                height="80%"
                justifyContent="center"
              >
                <Typography
                  variant="h1"
                  sx={[styles.title, { display: "inline-block" }]}
                  flexWrap="wrap"
                >
                  Transformando Dados em Performance para o seu{" "}
                  <TextScramble
                    color={"#e83750"}
                    phrases={["Provedor", "Data Center"]}
                  />
                  {/* <Box
                    component={"span"}
                    sx={{
                      color: "#e83750",
                      WebkitAnimation: "blinking 1.2s linear infinite",
                      display: "inline-block",
                    }}
                  >
                    |
                  </Box> */}
                </Typography>

                <Typography
                  variant="h2"
                  sx={[styles.subsubtitle, { display: "inline-block" }]}
                  flexWrap="wrap"
                >
                  Garanta o máximo desempenho e confiabilidade para a sua
                  infraestrutura, por meio do{" "}
                  <Box
                    component={"span"}
                    sx={[styles.subtitleDestaque, { fontWeight: "400" }]}
                  >
                    monitoramento
                  </Box>{" "}
                  em tempo real, dashboards intuitivas e análises avançadas.
                </Typography>
                <Box>
                  <Button
                    color="secondary"
                    onClick={() => scrollToSection("sectionProdutos")}
                    sx={[
                      styles.buttonTitle,
                      isDesktop ? {} : { width: "100%" },
                      { textTransform: "none" },
                    ]}
                    //href="#"
                    variant="contained"
                  >
                    Conheça nossas soluções
                  </Button>
                </Box>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                height="20%"
                justifyContent={isDesktop ? "left" : "center"}
                flexWrap={"wrap"}
              >
                <StickIsinght value="+150K" text="Equipamentos Monitorados" />
                <StickIsinght value="+300" text="Clientes Atendidos" />
                <StickIsinght value="+10Tb/s" text="de tráfego monitorado" />
              </Stack>
            </Stack>
            {isDesktop && (
              <Stack
                paddingLeft={"100px"}
                position="relative"
                width={{ mdx: "50%", xs: "100%" }}
                height={{ mdx: "100%", xs: "30%" }}
                justifyContent={{ mdx: "center", xs: "end" }}
                direction="row"
              >
                <Stack
                  width={"30%"}
                  height={"105%"}
                  mt={"-2%"}
                  sx={{
                    overflow: "hidden",
                    transform: "skew(-16deg, 4deg)",
                    position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    //src={"https://minimals.cc/assets/images/home/hero/dark_1.webp"}
                    src={imgs}
                    alt="Moving Box Mirro"
                    sx={{
                      position: "absolute",
                      maxWidth: "100%",
                      animation: `${moveImage2} linear 60s infinite`,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                  />

                  <Box
                    component={"img"}
                    //src={"https://minimals.cc/assets/images/home/hero/dark_1.webp"}
                    src={imgs}
                    alt="Moving Box Mirror"
                    sx={{
                      maxWidth: "100%",
                      position: "absolute",
                      animation: `${mirrorImage2} linear 60s infinite`,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                  />
                </Stack>
                <Stack
                  width={"100%"}
                  mr={"-20%"}
                  height={"105%"}
                  mt={"-2%"}
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    transform: "skew(-16deg, 4deg)",
                  }}
                >
                  <Box
                    component={"img"}
                    // src={"https://minimals.cc/assets/images/home/hero/dark_2.webp"}
                    src={imgs2}
                    alt="BIG Moving Box Mirro"
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      position: "absolute",
                      animation: `${moveImage} linear 150s infinite`,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                  />

                  <Box
                    component={"img"}
                    // src={"https://minimals.cc/assets/images/home/hero/dark_2.webp"}
                    src={imgs2}
                    alt="BIG Moving Box Mirror"
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      position: "absolute",
                      animation: `${mirrorImage} linear 150s infinite`,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {/* <Box sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(22, 28, 36, 0.48)",
          zIndex: 9,
          bottom: "0px",
          height: "80px",
          width: "50%",
          position: "absolute",
          clipPath: "polygon(0% 0%, 100% 100%, 0% 100%)",
          
          transform: "scaleX(-1)",
        
        }}></Box> */}

        <Box
          component="section"
          sx={[styles.contentPages]}
          id="sectionProdutos"
        >
          <Box sx={styles.contentSizeSection}>
            <Typography sx={[styles.mtitleContent]}>
              Precisa de ajuda com seu equipamento?
            </Typography>
            <Typography variant="h2" sx={[styles.titleContent]}>
              Conheça nossa ferramenta de monitoramento{" "}
              <Box
                component={"span"}
                sx={{ color: "easyMon.main", fontWeight: 350 }}
              >
                {" "}
                de Host
              </Box>
            </Typography>

            <Stack
              direction={{ mdx: "row", xs: "column" }}
              mt={5}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Stack width={{ mdx: "50%", xs: "100%" }}>
                <Box
                  component="img"
                  src={mompc}
                  sx={{ height: "auto", width: "auto", maxWidth: "100%" }}
                  data-aos={isDesktop ? "fade-left" : "slide-up"}
                  data-aos-delay="100"
                />
              </Stack>

              <Stack
                width={{ mdx: "50%", xs: "100%" }}
                justifyContent="center"
                spacing={3}
                alignItems={{ mdx: "start", xs: "center" }}
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <Box component="img" src={mon} sx={styles.paperProducts} />
                <Typography
                  sx={[
                    styles.textProducts,
                    { textAlign: { mdx: "left", xs: "center" } },
                  ]}
                >
                  Um reflexo da vitalidade da infraestrutura do seu provedor de
                  internet, o EasyMon é um sistema de monitoramento abrangente.
                  Ele supervisiona todos os aspectos, desde a gestão de rotas
                  BGP até a qualidade do sinal na casa dos clientes ou em
                  dispositivos de rádio. Com o EasyMon, seu provedor de internet
                  tem à disposição uma ferramenta poderosa para garantir a
                  excelência operacional e a satisfação do cliente.
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="start"
                  spacing={3}
                  flexWrap="wrap"
                  useFlexGap
                >
                  <CardDescription
                    title="Telas personalizadas por especialistas"
                    Icon={TuneIcon}
                    cor="#e84b21"
                  />
                  <CardDescription
                    title="Dados de latência e perda de pacotes dos ativos"
                    Icon={EqualizerIcon}
                    cor="#e84b21"
                  />
                  <CardDescription
                    title="Qualidade do sinal que seus clientes recebem"
                    Icon={AutoGraphIcon}
                    cor="#e84b21"
                  />
                </Stack>
                <Stack width="100%" direction="row" justifyContent="start">
                  <Button
                    color="easyMon"
                    variant="contained"
                    sx={{
                      fontSize: "1rem",
                      textTransform: "none",
                      //width: { mdx: "200px", xs: "100%" },
                      padding: "10px!important",
                    }}
                    onClick={() =>
                      sendWhats(
                        "Olá%2C+estou+interessado+no+EasyMon+e+gostaria+de+marcar+uma+reunião+para+discutir+mais+detalhes+do+produto.++Fico+no+aguardo%21"
                      )
                    }
                  >
                    Fale com um consultor
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Box sx={styles.line} mt={5} />
            <Typography sx={[styles.mtitleContent]}>
              Que tal ter o controle do tráfego da sua rede?
            </Typography>
            <Typography variant="h2" sx={[styles.titleContent]}>
              Conheça nosso Sistema de monitoramento{" "}
              <Box
                component={"span"}
                sx={{ color: "easyFlow.main", fontWeight: 350 }}
              >
                {" "}
                de Tráfego
              </Box>
            </Typography>

            <Stack
              direction={{ mdx: "row", xs: "column-reverse" }}
              mt={5}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Stack
                justifyContent="center"
                spacing={3}
                alignItems={{ mdx: "end", xs: "center" }}
                data-aos="zoom-in"
                data-aos-delay="100"
                width={{ mdx: "50%", xs: "100%" }}
              >
                <Box component="img" src={flow} sx={styles.paperProducts} />
                <Typography
                  sx={[
                    styles.textProducts,
                    { textAlign: { mdx: "right", xs: "center" } },
                  ]}
                >
                  O EasyFlow é uma ferramenta capaz de coletar, processar,
                  armazenar e exibir informações pertinentes ao tráfego de
                  equipamentos (em exemplo: roteadores, switches, firewalls) a
                  nível de camada 3, com informações como consumo em bits por
                  segundo, pacotes por segundo, flows por segundo de conteúdo
                  Google, Facebook, Netflix.
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={3}
                  flexWrap="wrap"
                  useFlexGap
                >
                  <CardDescription
                    title="Tráfego para os principais players do mercado"
                    Icon={TravelExploreIcon}
                    cor="#24c784"
                  />
                  <CardDescription
                    title="Filtro INET para separar tráfego IPv4 e IPv6"
                    Icon={FilterListIcon}
                    cor="#24c784"
                  />
                  <CardDescription
                    title="95th percentil de cada classe de tráfego"
                    Icon={SsidChartIcon}
                    cor="#24c784"
                  />
                </Stack>
                <Stack width="100%" direction="row" justifyContent="end">
                  <Button
                    color="easyFlow"
                    variant="contained"
                    sx={{
                      fontSize: "1rem",
                      textTransform: "none",
                      //width: { mdx: "200px", xs: "100%" },
                      padding: "10px!important",
                    }}
                    onClick={() =>
                      sendWhats(
                        "Olá%2C+estou+interessado+no+EasyFlow+e+gostaria+de+marcar+uma+reunião+para+discutir+mais+detalhes+do+produto.++Fico+no+aguardo%21"
                      )
                    }
                  >
                    Fale com um consultor
                    <ArrowForwardIcon />
                  </Button>
                </Stack>
              </Stack>
              <Stack width={{ mdx: "50%", xs: "100%" }}>
                <Box
                  component="img"
                  src={flowpc}
                  sx={{ height: "auto", width: "auto", maxWidth: "100%" }}
                  data-aos={isDesktop ? "fade-right" : "slide-up"}
                  data-aos-delay="100"
                />
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Box component="section" id="sectionPlanos" sx={[styles.contentPages]}>
          <Box sx={[styles.contentSizeSection]}>
            <Typography sx={[styles.mtitleContent]}>Gostou?</Typography>

            <Typography
              variant="h2"
              sx={[styles.titleContent, { textAlign: "center" }]}
            >
              Veja nossos outros produtos
            </Typography>

            <Typography sx={styles.textPrincing}>
              O sistema é pré-pago, sem cobrança de taxa de ativação, mas com
              pagamento antecipado para cada 30 dias de utilização.
            </Typography>

            <Stack
              width="100%"
              direction="row"
              spacing={{ lg: 6, xs: 2 }}
              mb={5}
              justifyContent="center"
              flexWrap="wrap"
              useFlexGap
            >
              {tiers.map((tier, index) => (
                <CardProductsOthers
                  tier={tier}
                  index={index}
                  key={"poducts" + index}
                />
              ))}
            </Stack>
          </Box>
        </Box>

        <Box
          id="sectionServiços"
          sx={[
            styles.contentPages,
            {
              // backgroundImage: `url(${backgroundMw})`,
              // backgroundSize: "cover",
              // backgroundAttachment: "fixed",
              // backgroundRepeat: "no-repeat",
              background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(5, 0, 0, 0.85)), center center / cover no-repeat  url(${backgroundHome})`,
              backgroundAttachment: "fixed",
              alignItems: "center",
            },
          ]}
        >
          <Box
            sx={[
              styles.contentSizeSection,
              { textAlign: "center", mb: "20px" },
            ]}
          >
            <Typography
              sx={[styles.mtitleContent, { color: "secondary.main" }]}
            >
              Ainda em dúvida?
            </Typography>
            <Typography
              variant="h2"
              sx={[
                styles.titleContent,
                { color: "white", textAlign: "center" },
              ]}
            >
              Mais sobre os nossos serviços
            </Typography>

            <Box sx={{ marginTop: "40px" }}>
              <Container maxWidth="100%" component="main">
                <Grid container spacing={5}>
                  {services.map((service, index) => (
                    <Grid key={service.title} item xs={12} sm={6} md={6} lg={3}>
                      <Paper
                        sx={styles.paperService}
                        variant="outlined"
                        data-aos="zoom-in"
                        data-aos-delay={index * 50}
                      >
                        <SvgIcon
                          component={service.icon}
                          sx={styles.iconServices}
                        />
                        <Typography variant="h6" sx={styles.titleServices}>
                          {service.title}
                        </Typography>
                        <Box
                          style={{ background: "#F43F59" }}
                          sx={styles.line}
                        />
                        <Paper sx={[styles.cardTextService]}>
                          <Typography variant="h6" sx={[styles.textServices]}>
                            {service.text}
                          </Typography>
                        </Paper>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Box>
        </Box>

        <Box component="section" sx={[styles.contentPages]} id="sectionSobre">
          <Box
            sx={[
              styles.contentSizeSection,
              { display: "flex", flexDirection: "column" },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="p" sx={[styles.mtitleContent]}>
                Onde atuamos?
              </Typography>

              <Typography variant="h2" sx={[styles.titleContent]}>
                Ampla presença em todo o território nacional
              </Typography>
              <Typography
                sx={[
                  styles.textRubik300,
                  { color: "black", textAlign: "center" },
                ]}
                mt={2}
              >
                Desde o Norte até o Sul, de Leste a Oeste, nossa marca ecoa,
                impactando vidas e negócios. Unimos tradição e vanguarda,
                antecipando tendências e desafiando limites, sempre com o
                objetivo de oferecer o melhor aos nossos parceiros e clientes.
              </Typography>

              <Box sx={[styles.cardInsignia]}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    fh={3}
                    sx={styles.gridItem}
                  >
                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={50}
                    >
                      <CrisisAlertIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          Monitoramento Preciso
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Tecnologias para monitoramento de ponta, garantindo
                          desempenho e estabilidade inigualáveis.
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={70}
                    >
                      <MovingIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          + 150 mil
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Mais de 150 mil equipamentos monitorados pela MW
                          soluções no mundo.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={90}
                    >
                      <SettingsBackupRestoreIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          Recuperação Rápida de Desastres
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Recuperação rápida após um desastre de hardware,
                          minimizando o impacto negativo nas operações do
                          cliente.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    fh={6}
                    sx={styles.gridItem}
                  >
                    <Box sx={{ width: "100%" }}>
                      <MapChart />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    fh={3}
                    sx={styles.gridItem}
                  >
                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={50}
                    >
                      <SupportAgentIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          Suporte Técnico Especializado
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Equipe especializada em monitoramento de hardware,
                          auxiliando nossos clientes em todas as etapas.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={70}
                    >
                      <DiamondIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          Confiabilidade Inabalável
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Monitoramento que Inspira Confiança.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={styles.item}
                      data-aos="zoom-in"
                      data-aos-delay={90}
                    >
                      <SpeedIcon sx={styles.iconInsignia} />
                      <Box sx={styles.textItem}>
                        <Typography variant="h6" sx={styles.titleInsignia}>
                          Análise Inteligente de Desempenho
                        </Typography>
                        <Typography variant="h6" sx={styles.textInsignia}>
                          Visando otimizar o desempenho, garantindo o máximo
                          potencial do seu equipamento.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          component="section"
          sx={[styles.contentPages]}
          id="contentfeedback"
        >
          <Box width="100%" sx={[styles.contentSizeSection]}>
            <Typography
              variant="h2"
              sx={[styles.titleContent, { textAlign: "center" }]}
            >
              Veja o feedback dos nossos clientes
            </Typography>

            <Carousel
              sx={styles.carouselFeedback}
              animation="slide"
              interval={4000}
              cycleNavigation={true}
              duration={1000}
              margin={isDesktop ? "20px" : null}
            >
              {feedback.map((feed, index) => (
                <Item
                  key={"feed" + index}
                  name={feed.name}
                  descricao={feed.descricao}
                  comment={feed.comment}
                  img={ex}
                />
              ))}

              {/* {feedbacks.map((feed, i) => (
        
              <Grid container spacing={3} width='100%'>
                {feed.page.map((card) => (
                  <Grid key={card.name} item xs={12} sm={6} md={6} lg={4}>
                    <Item
                      name={card.name}
                      descricao={card.descricao}
                      comment={card.comment}
                      img={ex}
                    />
                  </Grid>
                ))}
              </Grid>
           
          ))} */}
            </Carousel>
          </Box>
        </Box>
        <Box
          component="section"
          sx={[
            styles.contentPages,
            {
              background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(5, 0, 0, 0.85)), center center / cover no-repeat  url(${backgroundHome})`,
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              minHeight: "130px!important",
              padding: "0px!important",
              justifyContent: "center",
            },
          ]}
        >
          <Stack
            sx={styles.contentSizeSection}
            width="100%"
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-evenly"
            spacing={{ xs: 3 }}
            padding={{ xs: "20px", sm: "20px" }}
            data-aos="zoom-in"
            data-aos-delay={50}
          >
            {clients.map((client, i) => (
              <img
                key={i + "client"}
                src={client.logo}
                style={{
                  height: "50px",
                  width: "auto",
                  filter:
                    " invert(55%) sepia(0%) saturate(5433%) hue-rotate(353deg) brightness(93%) contrast(96%)",
                }}
              />
            ))}
          </Stack>
        </Box>

        <Box component="section" sx={[styles.contentPages]} id="sectionContato">
          <Box width="100%" sx={[styles.contentSizeSection]}>
            <Typography variant="h2" sx={styles.titleContent}>
              Contato
            </Typography>
            <Box
              sx={{
                fontFamily: "Rubik",
                alignItems: "center",
                display: "flex",
                alignContent: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box
                sx={[
                  {
                    display: "flex",
                    textAlign: "end",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    color: "black",
                  },
                  isDesktop ? { width: "40%" } : { width: "50%" },
                ]}
              >
                <Typography sx={styles.subtitleContact}>
                  Quer nos conhecer pessolmente?
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "300",
                    fontSize: isDesktop ? "26px" : "20px",
                  }}
                >
                  {" "}
                  Endereço{" "}
                </Typography>

                <Typography>
                  Rua Porto das Oficinas, 8960 – Natal/RN
                  <br />
                  Horário de atendimento:
                  <br /> 09h às 19h
                  <br />
                  contato@mw-solucoes.com
                </Typography>
                <br></br>
                <Button
                  href="https://goo.gl/maps/m4CNDRx8KRHudGRA7"
                  onMouseLeave={leavephone}
                  onMouseEnter={showMaps}
                  startIcon={<MapIcon />}
                  variant="contained"
                  sx={styles.buttonContact}
                  color="secondary"
                >
                  Veja nossa localização
                </Button>
              </Box>

              <Box
                component="img"
                src={imgPhone}
                alt="suitcase"
                width="15%"
                margin="20px"
                sx={{
                  display: isDesktop ? "flex" : "none",
                  WebkitAnimation: "pulse 3s linear infinite",
                }}
              />

              <Box
                sx={[
                  styles.linev,
                  { display: isDesktop ? "none" : "flex", margin: "10px" },
                ]}
              />
              <Box
                sx={[
                  {
                    display: "flex",
                    alignItems: "left",
                    flexDirection: "column",
                    color: "black",
                  },
                  isDesktop ? { width: "40%" } : { width: "50%" },
                ]}
              >
                {/* <Typography sx={styles.subtitleContact}>
              Ficou com dúvidas?
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: "400", fontSize: pagemobile ? "26px" : "20px" }}
            >
              {" "}
              Agende uma apresentação!{" "}
            </Typography>
            <Button
              onMouseLeave={leavephone}
              onMouseEnter={showAgenda}
              startIcon={<EventAvailableIcon />}
              href="https://meetings.hubspot.com/mw-solucoes"
              variant="contained"
              sx={styles.buttonContact}
            >
              Agendar
            </Button> */}
                <Typography sx={styles.subtitleContact}>
                  Precisa de suporte?
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "300",
                    fontSize: isDesktop ? "26px" : "20px",
                  }}
                >
                  {" "}
                  Fale com a gente pelo WhatsApp!{" "}
                </Typography>
                <Button
                  onClick={() =>
                    sendWhats(
                      "Ol%C3%A1%2C+Preciso+de+suporte+em+rela%C3%A7%C3%A3o+aos+servi%C3%A7os+MW"
                    )
                  }
                  onMouseLeave={leavephone}
                  onMouseEnter={showwhats}
                  startIcon={<WhatsAppIcon />}
                  variant="contained"
                  sx={styles.buttonContact}
                  color="secondary"
                >
                  Inicie uma conversa
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Footer */}
        <Container
          maxWidth="sx"
          component="footer"
          sx={{ backgroundColor: "transparent", width: "100%", padding: "1%" }}
        >
          <Stack
            borderRadius="10px"
            backgroundColor="#121212!important"
            justifyContent="space-between"
            direction="row"
            padding="15px 30px"
          >
            <Copyright />
            <Stack direction="row" spacing={2} alignItems="center">
              <Link
                color="#fff"
                href="https://instagram.com/mwsolucoesltda?igshid=MzRlODBiNWFlZA=="
              >
                <InstagramIcon />
              </Link>
              <Link
                color="#fff"
                href="https://www.facebook.com/mwsolucoesmonitoramento/"
              >
                <FacebookIcon />
              </Link>
              <Link
                color="#fff"
                href="https://www.linkedin.com/company/mw-solucoes/"
              >
                <LinkedInIcon />
              </Link>
            </Stack>
          </Stack>
        </Container>
        {/* End footer */}
      </Box>
    </Box>
  );
}

export default Home;
